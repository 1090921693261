<template>
  <span>
    <span>{{ price }}</span>
    <span
      class="ml-2"
      style="text-decoration: line-through; opacity: 0.4"
      v-if="discount && price_original != price"
      >{{ price_original }}</span
    >
  </span>
</template>

<script>
export default {
  name: "SingleProduct",
  props: {
    product: Object,
    discount: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    price() {
      let currency = this.$store.getters.getCurrentShop.info.valuta;
      let price =
        this.product.qty *
        (this.product.pris - this.product.pris * (this.product.rabatt / 100));
      this.product.parts.forEach(part => {
        price = price + parseInt(part.pris) * part.qty;
      });

      return this.$formatPrice(price, currency);
    },
    price_original() {
      let currency = this.$store.getters.getCurrentShop.info.valuta;
      if (!this.discount) {
        return 0;
      }
      let price_original = this.product.qty * this.product.pris;
      this.product.parts.forEach(part => {
        price_original = price_original + parseInt(part.pris) * part.qty;
      });
      return this.$formatPrice(price_original, currency);
    }
  }
};
</script>
