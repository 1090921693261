<template>
  <div class="navigation container-fluid" v-if="isAuthenticated">
    <div class="side-push">
      <nav class="navbar navbar-expand-lg">
        <router-link tag="a" class="navbar-brand" to="/"
          ><img src="@/assets/input_logo.svg"
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <template v-if="shops.length === 1">
              <li class="nav-item">
                <img
                  height="25"
                  :src="
                    'https://b2b-api.inputinterior.se/public/thumbnail/logo/' +
                    currentShop.shop_id
                  "
                  onerror="this.style.display='none'"
                />
              </li>
            </template>
            <template v-if="shops.length !== 1">
              <li v-for="shop in shops" :key="shop.shop_id" class="nav-item">
                <a
                  :class="{
                    'router-link-exact-active':
                      shop.shop_id === currentShop.shop_id,
                  }"
                  class="nav-link"
                  href="#"
                  @click="setActiveShop(shop.shop_id)"
                  >{{ shop.name }}</a
                >
              </li>
            </template>
            <li class="nav-item divider">
              <router-link
                :to="{ name: 'products' }"
                class="nav-link"
                href="#"
                >{{ $t("menu.products") }}</router-link
              >
            </li>
            <li class="nav-item" v-if="information">
              <router-link
                :to="{ name: 'information' }"
                class="nav-link"
                href="#"
                >{{ $t("menu.information") }}</router-link
              >
            </li>
            <li class="nav-item" v-if="showGreenified">
              <a class="nav-link" target="_blank" :href="greenifiedURL()"
              >{{ $t("menu.greenified") }}</a>
            </li>

            <li class="nav-item" v-if="getPermission !== 0">
              <router-link :to="{ name: 'orders' }" class="nav-link" href="#">{{
                $t("menu.orders")
              }}</router-link>
            </li>
            <li
              class="nav-item active"
              v-if="getPermission === 9 || getPermission === 8"
            >
              <router-link :to="{ name: 'users' }" class="nav-link" href="#">{{
                $t("menu.users")
              }}</router-link>
            </li>
            <li class="nav-item" v-if="getPermission !== 0">
              <router-link :to="{ name: 'cart' }" class="nav-link" href="#"
              >{{ $t("menu.cart") }}
                <template
                    v-if="
                    activeCart &&
                    activeCart.lines &&
                    activeCart.lines.length !== 0
                  "
                >({{ getCartItemCount() }})</template
                >
              </router-link>
            </li>
            <li class="nav-item active" v-if="$store.getters.isAuthenticated">
              <router-link :to="{ name: 'logout' }" class="nav-link" href="#">{{
                $t("menu.logout")
              }}</router-link>
            </li>
          </ul>

          <form class="form-inline my-2 my-lg-0"></form>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  methods: {
    getCartItemCount() {
      let lines = this.activeCart.lines.filter(
        (item) => item.prisl_underrad === "0"
      );
      let res = 0;
      for (let i = 0; i < lines.length; i++) {
        res += parseInt(lines[i].antal);
      }
      return res;
    },
    setActiveShop(id) {
      this.$store.dispatch("switchActiveShop", id);
    },

    greenifiedURL() {
      // Extract the TLD from the current window location
      let currentTLD = window.location.hostname.split('.').pop();

      // Specify the fallback TLD
      let fallbackTLD = 'se';

      // Define the domain and protocol
      let domainName = 'greenified';
      let protocol = 'https://www.';

      // Check if the current TLD is usable; otherwise, use the fallback
      let usableTLD = currentTLD ? currentTLD : fallbackTLD;

      // Construct the URL using the determined TLD
      return `${protocol}${domainName}.${usableTLD}`;
    }
  },
  computed: {

    showGreenified() {
      return  this.$store.getters.getCurrentShop.show_greenified
    },
    activeCart() {
      return this.$store.getters.getActiveCart;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthComplete;
    },
    getPermission() {
      return this.$store.getters.getPermission;
    },
    information() {
      return this.$store.getters.getInformation;
    },
    currentShop() {
      return this.$store.getters.getCurrentShop;
    },
    shops() {
      return this.$store.getters.getShops;
    },
  },
};
</script>
