<template>
  <div class="container-fluid">
    <div class="row hero">
      <div class="col text-center d-flex justify-content-center">
        <div class="searcharea-input d-flex justify-content-center">
          <div class="search-wrap">
            <input
              v-model="filter.search"
              :placeholder="$t('search.searchfield')"
              aria-autocomplete="list"
              aria-expanded="true"
              aria-label="SearchSensor"
              autocomplete="off"
              role="combobox"
              value=""
            />
            <div class="filters text-center mt-4">
              <div
                v-if="filter.category"
                class="selected-filter"
                @click="clearFilter('category')"
              >
                {{ $t("search.category") }}: {{ filter.category }}
                <span>✕</span>
              </div>
              <div
                v-if="filter.subcategory"
                class="selected-filter"
                @click="clearFilter('subcategory')"
              >
                {{ $t("search.subcategory") }}: {{ filter.subcategory }}
                <span>✕</span>
              </div>
              <div
                v-if="filter.brands"
                class="selected-filter"
                @click="clearFilter('brands')"
              >
                {{ $t("search.brand") }}: {{ filter.brands }} <span>✕</span>
              </div>
              <div
                v-if="filter.certifications"
                class="selected-filter"
                @click="clearFilter('certifications')"
              >
                Miljömärkning: {{ filter.certifications }} <span>✕</span>
              </div>
              <div
                v-if="filter.concept"
                class="selected-filter"
                @click="clearFilter('concept')"
              >
                {{ $t("search.concept") }}: {{ filter.concept }}
                <span>✕</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showFilter" class="row filter-products">
      <div class="col">
        <div class="side-push">
          <div class="row">
            <div class="col">
              <div class="label">{{ $t("search.category") }}</div>
              <v-select
                v-model="filter.category"
                :clearable="true"
                :options="primaryCategories"
                :placeholder="$t('search.all')"
                :searchable="false"
                transition="none"
                @input="handleFilterChange"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><span class="vue-select-chevron"></span
                  ></span>
                </template>
              </v-select>
            </div>
            <div class="col">
              <div class="label">{{ $t("search.subcategory") }}</div>
              <v-select
                v-model="filter.subcategory"
                :clearable="true"
                :options="secondaryCategories"
                :placeholder="$t('search.all')"
                :searchable="false"
                transition="none"
                @input="handleFilterChange"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><span class="vue-select-chevron"></span
                  ></span>
                </template>
              </v-select>
            </div>

            <div class="col">
              <div class="label">{{ $t("search.brand") }}</div>
              <v-select
                v-model="filter.brands"
                :clearable="true"
                :options="brands"
                :placeholder="$t('search.all')"
                :searchable="false"
                transition="none"
                @input="handleFilterChange"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><span class="vue-select-chevron"></span
                  ></span>
                </template>
              </v-select>
            </div>
            <div class="col">
              <div class="label">{{ $t("search.concept") }}</div>
              <v-select
                v-model="filter.concept"
                :clearable="true"
                :options="concepts"
                :placeholder="$t('search.all')"
                :searchable="false"
                transition="none"
                @input="handleFilterChange"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><span class="vue-select-chevron"></span
                  ></span>
                </template>
              </v-select>
            </div>
            <div class="col">
              <div class="label">{{ $t("search.certificates") }}</div>
              <v-select
                v-model="filter.certifications"
                :clearable="false"
                :options="languageSpecificCertifications"
                :placeholder="$t('search.all')"
                :searchable="false"
                transition="none"
                @input="handleFilterChange"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"
                    ><span class="vue-select-chevron"></span
                  ></span>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-push">
      <div v-if="paginatedProducts.length === 0" class="text-center pt-5 pb-5">
        <p>{{ $t("search.no-products") }}</p>
      </div>
      <div class="row mb-4">
        <div class="col-12 text-right">
          <span class="pagination-help">
            {{ $t("search.showing") }}
            {{ (currentPage - 1) * itemsPerPage + 1 }} -
            {{ Math.min(currentPage * itemsPerPage, filteredProducts.length) }}
            {{ $t("search.of") }} {{ filteredProducts.length }}
            {{ $t("search.num-results") }}
          </span>
          <span class="pagination-help ml-2 pl-2 mr-2 pr-2">|</span>
          <label for="itemsPerPage" class="pagination-help">
            {{ $t("search.itemsPerPage") }}:
          </label>
          <span
            v-for="option in itemsPerPageOptions"
            :key="option"
            class="pagination-option"
          >
            <a
              href="#"
              @click.prevent="changeItemsPerPage(option)"
              :class="{ active: itemsPerPage === option }"
              class="ml-2 pagination-help"
              >{{ option }}</a
            >
          </span>
        </div>
      </div>

      <div class="row">
        <div
          v-for="product in paginatedProducts"
          :key="product.uid"
          class="col-12 col-md-2-5"
        >
          <single-product :product="product"></single-product>
        </div>
      </div>

      <!-- Pagination controls -->
      <div class="row mt-4">
        <div class="col-12 text-center">
          <nav aria-label="Page navigation">
            <ul class="pagination">
              <li
                class="page-item"
                v-for="page in totalPages"
                :key="page"
                :class="{ active: page === currentPage }"
              >
                <a
                  class="page-link ml-2 mr-2"
                  @click.prevent="changePage(page)"
                  href="#"
                  >{{ page }}</a
                >
              </li>
            </ul>
          </nav>
          <p class="pagination-help mt-5">
            {{ $t("search.showing") }}
            {{ (currentPage - 1) * itemsPerPage + 1 }} -
            {{ Math.min(currentPage * itemsPerPage, filteredProducts.length) }}
            {{ $t("search.of") }} {{ filteredProducts.length }}
            {{ $t("search.num-results") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleProduct from "../components/producs/SingleProduct";
import Dropdown from "../components/producs/Dropdown";

export default {
  name: "Home",
  components: {
    SingleProduct,
    Dropdown
  },
  data() {
    return {
      showFilter: true,
      allProducts: [],
      filter: {
        search: "",
        concept: "",
        brands: "",
        category: "",
        subcategory: "",
        certifications: ""
      },
      currentPage: 1,
      itemsPerPage: 30,
      itemsPerPageOptions: [15, 30, 45, 60]
    };
  },
  created() {
    this.loadAllProducts();
    this.initializeFiltersFromRoute(this.$route.query);
  },
  methods: {
    handleFilterChange() {
      this.currentPage = 1;
      this.updateURL();
    },
    resetPage() {
      this.currentPage = 1;
    },
    changePage(page) {
      this.currentPage = page;
      this.updateURL();
      this.smoothScrollTo(".filter-products");
    },
    changeItemsPerPage(number) {
      this.itemsPerPage = number;
      this.currentPage = 1;
      this.updateURL();
      this.smoothScrollTo(".filter-products");
    },
    smoothScrollTo(selector) {
      const element = document.querySelector(selector);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth"
        });
      }
    },
    loadAllProducts() {
      this.allProducts = this.$store.getters.getProducts;
    },
    clearFilter(field) {
      this.filter[field] = "";
      this.currentPage = 1;
      this.updateURL();
    },
    updateURL() {
      const query = {};

      Object.entries(this.filter).forEach(([key, value]) => {
        if (value) {
          query[key] = value;
        }
      });

      if (this.currentPage > 1) {
        query.page = this.currentPage.toString();
      }

      this.$router.push({ query });
    },
    initializeFiltersFromRoute(query) {
      this.filter.category = query.category || "";
      this.filter.subcategory = query.subcategory || "";
      this.filter.brands = query.brands || "";
      this.filter.certifications = query.certifications || "";
      this.filter.search = query.search || "";

      this.currentPage = query.page ? parseInt(query.page) : 1;

      if (query.concept) {
        this.$nextTick(() => {
          this.filter.concept =
            this.concepts.find(item => item === query.concept) || "";
        });
      } else {
        this.filter.concept = "";
      }
    },
    applyAllFilters(exclude) {
      let filteredProducts = [...this.allProducts];

      if (exclude !== "search" && this.filter.search) {
        filteredProducts = filteredProducts.filter(
          item =>
            item.benamn1 &&
            item.benamn1
              .toUpperCase()
              .includes(this.filter.search.toUpperCase())
        );
      }
      if (exclude !== "concept" && this.filter.concept) {
        filteredProducts = filteredProducts.filter(
          item => item.kocncet && item.kocncet.includes(this.filter.concept)
        );
      }
      if (exclude !== "brands" && this.filter.brands) {
        filteredProducts = filteredProducts.filter(
          item => item.varumarke && item.varumarke.includes(this.filter.brands)
        );
      }
      if (exclude !== "category" && this.filter.category) {
        filteredProducts = filteredProducts.filter(
          item =>
            item.artikelkategori_huvud &&
            item.artikelkategori_huvud.includes(this.filter.category)
        );
      }
      if (exclude !== "subcategory" && this.filter.subcategory) {
        filteredProducts = filteredProducts.filter(
          item =>
            item.artikelkategori &&
            item.artikelkategori.includes(this.filter.subcategory)
        );
      }
      if (exclude !== "certifications" && this.filter.certifications) {
        const currentLanguage = this.$i18n.locale;
        filteredProducts = filteredProducts.filter(
          product =>
            product.certifications &&
            product.certifications[currentLanguage] &&
            product.certifications[currentLanguage].some(cert =>
              this.filter.certifications.includes(cert)
            )
        );
      }

      return filteredProducts;
    }
  },
  computed: {
    filteredProducts() {
      let products = [...this.allProducts];

      if (this.filter.search) {
        products = products.filter(
          item =>
            (item.benamn1 &&
              item.benamn1
                .toUpperCase()
                .includes(this.filter.search.toUpperCase())) ||
            (item.sokord &&
              item.sokord
                .toUpperCase()
                .includes(this.filter.search.toUpperCase()))
        );
      }
      if (this.filter.concept) {
        products = products.filter(
          item => item.kocncet && item.kocncet.includes(this.filter.concept)
        );
      }
      if (this.filter.brands) {
        products = products.filter(
          item => item.varumarke && item.varumarke.includes(this.filter.brands)
        );
      }
      if (this.filter.category) {
        products = products.filter(
          item =>
            item.artikelkategori_huvud &&
            item.artikelkategori_huvud.includes(this.filter.category)
        );
      }
      if (this.filter.subcategory) {
        products = products.filter(
          item =>
            item.artikelkategori &&
            item.artikelkategori.includes(this.filter.subcategory)
        );
      }
      if (this.filter.certifications) {
        const currentLanguage = this.$i18n.locale;
        products = products.filter(
          product =>
            product.certifications &&
            product.certifications[currentLanguage] &&
            product.certifications[currentLanguage].some(cert =>
              this.filter.certifications.includes(cert)
            )
        );
      }

      return products;
    },
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      return this.filteredProducts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredProducts.length / this.itemsPerPage);
    },
    concepts() {
      let filteredProducts = this.applyAllFilters("concept");
      let concepts = filteredProducts.flatMap(item => {
        if (item.kocncet) {
          return item.kocncet.split(";");
        }
        return [];
      });
      return [...new Set(concepts)].sort();
    },
    languageSpecificCertifications() {
      let currentLanguage = this.$i18n.locale;
      let filteredProducts = this.applyAllFilters("certifications");
      let certifications = filteredProducts.flatMap(product => {
        if (product.certifications && product.certifications[currentLanguage]) {
          return product.certifications[currentLanguage];
        }
        return [];
      });
      return [...new Set(certifications)].sort();
    },
    secondaryCategories() {
      let filteredProducts = this.applyAllFilters("subcategory");
      if (this.filter.category) {
        return [
          ...new Set(
            filteredProducts
              .filter(
                item => item.artikelkategori_huvud === this.filter.category
              )
              .map(item => item.artikelkategori)
              .filter(Boolean)
          )
        ].sort();
      }
      return [
        ...new Set(
          filteredProducts.map(item => item.artikelkategori).filter(Boolean)
        )
      ].sort();
    },
    primaryCategories() {
      let filteredProducts = this.applyAllFilters("category");
      return [
        ...new Set(
          filteredProducts
            .map(item => item.artikelkategori_huvud)
            .filter(Boolean)
        )
      ].sort();
    },
    brands() {
      let filteredProducts = this.applyAllFilters("brands");
      return [
        ...new Set(
          filteredProducts.map(product => product.varumarke).filter(Boolean)
        )
      ].sort();
    }
  },
  watch: {
    filter: {
      handler(newFilter, oldFilter) {
        // Check if anything other than page number changed
        if (JSON.stringify(newFilter) !== JSON.stringify(oldFilter)) {
          this.currentPage = 1; // Reset page when any filter changes
          this.updateURL();
        }
      },
      deep: true
    },
    $route(to, from) {
      if (JSON.stringify(to.query) !== JSON.stringify(from.query)) {
        this.initializeFiltersFromRoute(to.query);
      }
    }
  },
  mounted() {
    this.loadAllProducts();
  }
};
</script>
<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-item .page-link {
  margin: 5px;
  border-radius: 100% !important;
  height: 32px;
  min-width: 32px;
  padding: 0;
  display: inline-block;
  background-color: transparent;
  transition: all 0.15s;
  border: 1px solid #e5e5e5;
  font-family: AvenirLTStd-Roman, sans-serif;
  letter-spacing: 1.71px;
  text-transform: uppercase;
  line-height: 32px;
  font-size: 14px;
  text-align: center;
  color: #000;
}

.page-item .page-link:hover,
.page-item .page-link:focus {
  color: black;
  background-color: #e9ecef; /* Optional: change hover/focus background color */
  border-color: black;
}

.page-item.active .page-link {
  background-color: black;
  border-color: black;
  color: white;
}

.page-item.disabled .page-link {
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: #6c757d;
}

.pagination-help {
  color: #000 !important;
  font-weight: 500;
  letter-spacing: 1.4px;
  margin-bottom: 20px;
  text-transform: uppercase;
  line-height: 1em;
  font-size: 12px;
  text-align: center;
}

.pagination-help.active {
  color: #e7ae27 !important;
}
</style>
