<template>
  <div class="dropdown">
    <select>
      <option>{{ label }}</option>
      <option v-for="load in payload" :key="load">{{ load }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  props: {
    payload: Object,
    label: String,
  },
};
</script>
