<template>
  <ul>
    <li
      :class="{ active: $i18n.locale === locale.code }"
      v-for="locale in locales"
      :key="locale.label"
      @click="switchLocale(locale.code)"
    >
      {{ locale.label }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  methods: {
    // <---------------------------
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },
  },
  data() {
    return {
      locales: [
        { code: "sv", label: "Svenska" },
        { code: "no", label: "Norsk" },
        { code: "da", label: "Dansk" },
        { code: "en", label: "English" },
      ],
    };
  },
};
</script>

<style scoped>
ul {
  padding: 0;
  margin: 0;
}
li {
  opacity: 0.6;
  list-style: none;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  margin-right: 20px;
}

li.active {
  opacity: 0.7;
  font-weight: 600;
}
</style>
