import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
import InputAPI from "@/services/api/input";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

function initialState() {
  return {
    auth: {
      username: "",
      isAuthenticated: false,
      authComplete: false,
      token: false
    },
    user: false,
    currentShop: {},
    shops: [],
    cart: { lines: [], status: null, customer: {} },
    carts: [],
    customers: [],
    products: [],
    punchout: false
  };
}

const state = initialState;

//to handle actions
const actions = {
  switchActiveShop({ commit }, id) {
    commit("SET_CURRENT_SHOP", id);
  },

  getProducts({ commit }) {
    return InputAPI.getProducts()
      .then(response => {
        commit("SET_PRODUCTS", response.data.data);
      })
      .catch(error => {
        commit("SET_PRODUCTS", []);
      });
  },

  getCarts({ commit }) {
    return InputAPI.getCarts()
      .then(response => {
        commit("SET_CARTS", response.data.data);
      })
      .catch(error => {
        commit("SET_CARTS", []);
      });
  },

  getCustomers({ commit }) {
    return InputAPI.getCustomers()
      .then(response => {
        commit("SET_CUSTOMERS", response.data);
      })
      .catch(error => {
        commit("SET_CUSTOMERS", []);
      });
  },

  completeAuth({ commit }) {
    commit("SET_AUTH_COMPLETE", []);
  },

  getShops({ commit }) {
    return InputAPI.getShops()
      .then(response => {
        commit("SET_SHOPS", response.data.data);
        commit("SET_CURRENT_SHOP", response.data.data[0]);
      })
      .catch(error => {
        commit("SET_SHOPS", []);
      });
  },

  setActiveCart({ commit }, payload) {
    commit("SET_CART", payload);
  },

  getUser({ commit }) {
    return InputAPI.currentUser().then(response => {
      commit("SET_USER", response.data);
    });
  },

  createCart({ commit }, payload) {
    return InputAPI.createCart(payload.name, payload.shop_id)
      .then(response => {
        commit("SET_CART", response.data.data);
        return response.data.data;
      })
      .catch(error => {
        throw error;
      });
  },

  addProductCart({ commit }, payload) {
    return InputAPI.addProductCart(
      payload.cart_id,
      payload.shop_id,
      payload.product
    )
      .then(response => {
        // commit("SET_CART", response.data.data);
      })
      .catch(error => {
        throw error;
      });
  },

  doAuthentication({ commit }, credentials) {
    return InputAPI.login(
      credentials.username,
      credentials.password,
      credentials.punchout
    )
      .then(response => {
        commit("SET_AUTH", response.data);
      })
      .catch(error => {
        throw error;
      });
  },

  setPunchout({ commit }, payload) {
    commit("SET_PUNCHOUT", payload);
  },

  doLogout({ commit }, credentials) {
    InputAPI.logout()
      .then(response => {
        commit("SET_LOGOUT_AUTH");
      })
      .catch(error => {
        commit("SET_LOGOUT_AUTH");
      });
  }
};

const mutations = {
  SET_PRODUCTS(state, payload) {
    state.products = payload;
  },

  SET_AUTH_COMPLETE(state, payload) {
    const carts = state.carts.filter(
      cart =>
        cart.shop_id == state.currentShop.shop_id &&
        !cart.status.includes("AN") &&
        !cart.status.includes("GK") &&
        !cart.status.includes("ÖV")
    );
    state.cart = carts.find(cart => cart.shop_id == state.currentShop.shop_id);
    state.auth.authComplete = true;
  },

  SET_CART(state, payload) {
    state.carts = [
      ...state.carts.filter(element => element.id !== payload.id),
      payload
    ];
    state.cart = payload;
  },

  SET_CARTS(state, payload) {
    state.carts = payload;
  },

  SET_SHOPS(state, payload) {
    state.shops = payload;
  },

  SET_USER(state, payload) {
    state.user = payload;
  },

  SET_CUSTOMERS(state, payload) {
    state.customers = payload;
  },
  SET_CURRENT_SHOP(state, payload) {
    if (typeof payload === "object" && payload !== null) {
      state.currentShop = payload;
    } else {
      state.currentShop = state.shops.find(e => e.shop_id === payload);
    }

    state.cart = state.carts.find(
      cart =>
        cart.shop_id == state.currentShop.shop_id &&
        !cart.status.includes("AN") &&
        !cart.status.includes("GK") &&
        !cart.status.includes("ÖV")
    );
  },

  SET_PUNCHOUT(state, payload) {
    state.punchout = payload;
  },

  SET_LOGOUT_AUTH(state) {
    state.auth.authComplete = false;
    state.auth.isAuthenticated = false;
    state.auth.token = false;
    state.shops = [];
    state.user = false;
    state.basket = [];
    state.products = [];
    state.cart = {};
    state.customers = [];
    state.carts = [];
    state.currentShop = {};
    state.punchout = false;
    localStorage.clear();
    localStorage.setItem("token", false);
  },

  SET_AUTH(state, payload) {
    state.auth.isAuthenticated = true;
    state.auth.token = payload.access_token;
    localStorage.setItem("token", payload.access_token);
    Axios.defaults.headers.common["Authorization"] =
      "Bearer " + payload.access_token;
  }
};

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state,
  mutations,
  actions,
  getters: {
    isAuthenticated: state => state.auth.isAuthenticated,
    isAuthComplete: state => state.auth.authComplete,
    relatedProducts: state => category => {
      return state.products
        .filter(product => product.artikelkategori_huvud === category)
        .splice(0, 5);
    },

    getPunchout: state => state.punchout,
    getCustomers: state => {
      return state.customers;
    },
    getCurrentShop: state => {
      return state.currentShop;
    },
    getActiveCart: state => {
      return state.cart || { lines: [], customer: {}, status: null };
    },
    getCartById: state => id => {
      return state.carts.find(cart => cart.id == id);
    },
    getCompletedCarts: state => {
      return state.carts.filter(
        cart =>
          cart.shop_id == state.currentShop.shop_id &&
          (cart.status.includes("GK") || cart.status.includes("ÖV"))
      );
    },
    getActiveCarts: state => {
      return state.carts.filter(
        cart =>
          cart.shop_id == state.currentShop.shop_id &&
          !cart.status.includes("AN") &&
          !cart.status.includes("GK") &&
          !cart.status.includes("ÖV")
      );
    },

    getProduct: state => (shop, uid) => {
      return state.products.find(
        product => product.butik === shop && product.uid === uid
      );
    },

    getShops: state => {
      return state.shops.map(shop => ({
        shop_id: shop.shop_id,
        name: shop.info.namn
      }));
    },

    getProducts: state => {
      return state.products.filter(
        product => product.butik == state.currentShop.shop_id
      );
    },

    getInformation: state => {
      let result = state.currentShop.information ?? "";

      if (result.length > 0) {
        return result;
      } else {
        return false;
      }
    },
    getPermission: state => {
      return state.currentShop.permission;
    },

    getUsername: state => {
      return state.user.username;
    },

    requireNewPassword: state => {
      if (state.user === false) {
        return false;
      }
      return state.user.reset_password === 1;
    }
  }
});
