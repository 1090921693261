var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isAuthenticated)?_c('div',{staticClass:"navigation container-fluid"},[_c('div',{staticClass:"side-push"},[_c('nav',{staticClass:"navbar navbar-expand-lg"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"tag":"a","to":"/"}},[_c('img',{attrs:{"src":require("@/assets/input_logo.svg")}})]),_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ml-auto"},[(_vm.shops.length === 1)?[_c('li',{staticClass:"nav-item"},[_c('img',{attrs:{"height":"25","src":'https://b2b-api.inputinterior.se/public/thumbnail/logo/' +
                  _vm.currentShop.shop_id,"onerror":"this.style.display='none'"}})])]:_vm._e(),(_vm.shops.length !== 1)?_vm._l((_vm.shops),function(shop){return _c('li',{key:shop.shop_id,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{
                  'router-link-exact-active':
                    shop.shop_id === _vm.currentShop.shop_id,
                },attrs:{"href":"#"},on:{"click":function($event){return _vm.setActiveShop(shop.shop_id)}}},[_vm._v(_vm._s(shop.name))])])}):_vm._e(),_c('li',{staticClass:"nav-item divider"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'products' },"href":"#"}},[_vm._v(_vm._s(_vm.$t("menu.products")))])],1),(_vm.information)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'information' },"href":"#"}},[_vm._v(_vm._s(_vm.$t("menu.information")))])],1):_vm._e(),(_vm.showGreenified)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"target":"_blank","href":_vm.greenifiedURL()}},[_vm._v(_vm._s(_vm.$t("menu.greenified")))])]):_vm._e(),(_vm.getPermission !== 0)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'orders' },"href":"#"}},[_vm._v(_vm._s(_vm.$t("menu.orders")))])],1):_vm._e(),(_vm.getPermission === 9 || _vm.getPermission === 8)?_c('li',{staticClass:"nav-item active"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'users' },"href":"#"}},[_vm._v(_vm._s(_vm.$t("menu.users")))])],1):_vm._e(),(_vm.getPermission !== 0)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'cart' },"href":"#"}},[_vm._v(_vm._s(_vm.$t("menu.cart"))+" "),(
                  _vm.activeCart &&
                  _vm.activeCart.lines &&
                  _vm.activeCart.lines.length !== 0
                )?[_vm._v("("+_vm._s(_vm.getCartItemCount())+")")]:_vm._e()],2)],1):_vm._e(),(_vm.$store.getters.isAuthenticated)?_c('li',{staticClass:"nav-item active"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'logout' },"href":"#"}},[_vm._v(_vm._s(_vm.$t("menu.logout")))])],1):_vm._e()],2),_c('form',{staticClass:"form-inline my-2 my-lg-0"})])],1)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
}]

export { render, staticRenderFns }