<template>
  <div style="position: fixed;bottom: 0; left: 0; width: 100%; z-index: 0">
    <footer class="contact-footer" v-if="isAuthenticated">
      <div class="container-fluid">
        <div class="side-push">
          <contact-form></contact-form>
        </div>
      </div>
    </footer>
    <footer class="page-footer" v-if="isAuthenticated">
      <div class="container-fluid">
        <div class="side-push">
          <div class="row">
            <div class="col-4 about">
              <h2>{{ $t("footer.title") }}</h2>
              <p>{{ $t("footer.text") }}</p>
            </div>
            <div class="col-8 text-right">
              <locale-switcher></locale-switcher>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import localeSwitcher from "@/components/layout/localeSwitcher";
import ContactForm from "@/components/forms/ContactForm.vue";
export default {
  props: {},
  components: { localeSwitcher, ContactForm },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthComplete;
    }
  }
};
</script>
