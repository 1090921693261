<template>
  <router-link
    tag="div"
    :to="{
      name: 'product.view',
      params: { shop: product.butik, uid: product.uid }
    }"
    class="single-product"
  >
    <div class="product-image-container">
      <div
        class="product-image"
        :style="{ backgroundImage: `url(${product.bild})` }"
      ></div>
    </div>
    <h4>{{ product.benamn1 }}</h4>
    <div v-if="product.benamn2" class="text-muted small pt-1 pb-1">
      {{ product.benamn2 }}
    </div>

    <total-price :product="product" :discount="false" />
  </router-link>
</template>
<script>
import TotalPrice from "@/components/producs/TotalPrice";

export default {
  name: "SingleProduct",
  components: { TotalPrice },
  props: {
    product: Object
  }
};
</script>
