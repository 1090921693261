import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Axios from "axios";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vSelect from "vue-select";
import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";

Vue.component("v-select", vSelect);

/** CSS */
import "@/assets/style/style.scss";
import "vue-select/dist/vue-select.css";
import i18n from "./i18n";

if (process.env.NODE_ENV === "production") {
  flare.light("O1VPkq38nH2QGABicMtthmDN9OnCtpUq");
}

Vue.use(flareVue);

Vue.use(BootstrapVue);
Vue.use(Toast, {});
Vue.config.productionTip = false;

const token = localStorage.getItem("token");
Vue.prototype.$http = Axios;

if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token;
}
function formatPrice(price_original, currency) {
  let formattedPrice;
  let options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  };

  switch (currency) {
    case "NOK":
    case "DKK":
      formattedPrice =
        new Intl.NumberFormat("sv-SE", options).format(price_original) + " kr";
      break;
    case "EUR":
      options.style = "currency";
      options.currency = currency;
      formattedPrice = new Intl.NumberFormat("sv-SE", options).format(
        price_original
      );
      break;
    case "SEK":
      formattedPrice =
        new Intl.NumberFormat("sv-SE", options).format(price_original) + " kr";
      break;
    default:
      options.style = "currency";
      options.currency = currency;
      formattedPrice = new Intl.NumberFormat("sv-SE", options).format(
        price_original
      );
      break;
  }

  return formattedPrice;
}

Vue.prototype.$formatPrice = formatPrice;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
