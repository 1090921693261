import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "products",
    meta: { requiresAuth: true, disableScroll: true },
    component: Home
  },

  {
    path: "/information",
    name: "information",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "auth-login" */ "../views/pages/content/Information"
      )
  },

  {
    path: "/cart",
    name: "cart",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "auth-login" */ "../views/pages/cart/Cart")
  },

  {
    path: "/support",
    name: "support",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "suppor" */ "../views/pages/Support")
  },

  {
    path: "/orders",
    name: "orders",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "auth-login" */ "../views/pages/orders/Orders"
      )
  },
  {
    path: "/users",
    name: "users",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "auth-login" */ "../views/pages/admin/Users")
  },
  {
    path: "/orders/:id",
    name: "orders.view",
    meta: { requiresAuth: true },
    props: route => ({ id: String(route.params.id) }),
    component: () =>
      import(
        /* webpackChunkName: "single-product" */ "../views/pages/orders/ViewOrder.vue"
      )
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "auth-login" */ "../views/pages/auth/Login")
  },
  {
    path: "/login/ext",
    name: "login.ext",
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackChunkName: "auth-login" */ "../views/pages/auth/LoginExt"
      )
  },
  {
    path: "/logout",
    name: "logout",
    meta: { requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "auth-logout" */ "../views/pages/auth/Logout")
  },
  {
    path: "/product/:shop/:uid",
    name: "product.view",
    meta: { requiresAuth: true },
    props: route => ({
      shop: String(route.params.shop),
      uid: String(route.params.uid)
    }),
    component: () =>
      import(
        /* webpackChunkName: "single-product" */ "../views/pages/products/SingleProduct.vue"
      )
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  }
});

/**
 * beforeEach hook.
 *
 * Guard routes based on their permission property.
 */
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  if ((!loggedIn || loggedIn === "false") && to.meta.requiresAuth) {
    next("/login");
  } else {
    next();
  }
});

export default router;
