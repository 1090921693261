<template>
  <div id="app">
    <navigation></navigation>
    <div class="content-wrap">
      <router-view />
    </div>

    <footer-area></footer-area>
  </div>
</template>

<script>
import navigation from "./components/layout/navigation";
import footerArea from "./components/layout/footer";
export default {
  components: {
    navigation,
    footerArea
  }
};
</script>
<style lang="scss">
#app {
}
</style>
